import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link, FormattedMessage, FormattedHTMLMessage, } from "gatsby-plugin-intl"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'
import get from 'lodash/get'



const ServicesPage = ({data, intl}) => {
  
  const { services } = data
   
  const servicesData = get(services, 'edges', []).map(x => x.node).filter(node => node.lang === intl.locale)
  
  return (
  <Layout>
    <SEO title="Services - INMAGIK" />
    <div className="jumbo-title jumbo-small container-fluid text-center bg-black">
      <div className="display-3 font-200 text-white">
        <FormattedMessage id="services">Services</FormattedMessage>
      </div>
      <div className="row my-4">
        <div className="col-md-6 offset-md-3 text-center">
          <p className="lead font-200 text-white">
            <FormattedMessage id="services_title">
              Our core business is software development with open source tools and frameworks.
            </FormattedMessage>
          </p>
          <hr/>
          <p className="lead font-200 text-white">

            <FormattedHTMLMessage id="services_title_2">
              We are specialized in building interfaces with <b>Javascript</b> and <b>React</b> and server logic and APIs with <b>Python</b> and <b>Django</b>.
            </FormattedHTMLMessage>
          </p>
        </div>
      </div>
    </div>

    <div className="main-content container">
      <div className="row my-3 justify-content-center">
        { servicesData.map((service, i) => (<div key={i} className="col-md-6 p-3 text-center">
          <div className="p-4">
          <p className="display-small font-200">
            {service.title}
          </p>
          <p className="lead font-200" dangerouslySetInnerHTML={{ __html: service.description }}
          ></p>
        </div>
        </div>))}
      
        
      </div>
      
      <hr></hr>
      <div className="lead font-200 text-center my-5">
          <FormattedMessage id="services_learn_more">Learn more about our</FormattedMessage>
          <br/>
          <Link className="btn btn-primary text-uppercase" to={'/tech'}><FormattedMessage id="services_technology_stack">technology stack</FormattedMessage></Link>.
        </div>
      <hr className="mb-5"></hr>

    </div>
  </Layout>
)}

export default injectIntl(ServicesPage)

export const query = graphql`
  query {
    services: allServicesYaml {
      edges {
        node {
          title
          description
          lang
        }
      }
    }
  }
`